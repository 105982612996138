body {
  background-color: #f7c5cc;
}
.App {
  font-family: sans-serif;
  text-align: center;
}

.dropbtn {
  background-color: #cc313d;
  width: 180px;
  color: white;
  padding: 16px;
  font-size: 22px;
  border: none;
  border-radius: 9px;
}

.dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown-content {
  display: none;
  background-color: #f1f1f1;
  width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3), 0px 0px 1px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.dropdown-content h5 {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content h5:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
  cursor: pointer;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
